import React from "react";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import Loader from "./shared/Loader";

// Layouts
import Layout2 from "./shared/layouts/Layout2";
import LayoutBlank from "./shared/layouts/LayoutBlank";

// Lazy load component
const lazy = (cb) =>
  loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> });

export const DefaultLayout = Layout2;

export const titleTemplate = "%s - ProServe Alliance";

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const defaultRoute = "/dashboard";
export const routes = [
  {
    path: "/login",
    component: lazy(() => import("./components/authentication/Login")),
    layout: LayoutBlank,
  },
  {
    path: "/sign-up",
    component: lazy(() => import("./components/authentication/SignUp")),
    layout: LayoutBlank,
  },
  {
    path: "/sign-out",
    component: lazy(() => import("./components/authentication/SignOut")),
    layout: LayoutBlank,
  },
  {
    path: "/login/impersonate/:token",
    component: lazy(() =>
      import("./components/authentication/ImpersonateUser")
    ),
    layout: LayoutBlank,
  },
  {
    path: "/dashboard",
    component: lazy(() => import("./components/dashboards/Dashboard")),
  },
  {
    path: "/reset-password",
    component: lazy(() => import("./components/authentication/Login")),
    layout: LayoutBlank,
    routeVariables: { showReset: true },
  },
  {
    path: "/quickbooksactivation",
    component: lazy(() => import("./components/partners/QuickbooksActivation")),
  },
  {
    path: "/quickbookscustomers",
    component: lazy(() => import("./components/partners/QuickbooksCustomers")),
  },
  {
    path: "/quickbooksitems",
    component: lazy(() => import("./components/partners/QuickbooksItems")),
  },
  {
    path: "/quickbooksvendors",
    component: lazy(() => import("./components/partners/QuickbooksVendors")),
  },
  {
    path: "/dashboard/:pid",
    component: lazy(() => import("./components/dashboards/Dashboard")),
  },
  {
    path: "/clients",
    component: lazy(() => import("./components/clients/Clients")),
  },
  {
    path: "/clients/:id",
    component: lazy(() => import("./components/clients/Client")),
  },
  {
    path: "/addresses",
    component: lazy(() => import("./components/addresses/Addresses")),
  },
  {
    path: "/addresses/:id",
    component: lazy(() => import("./components/addresses/Address")),
  },
  {
    path: "/jobs",
    component: lazy(() => import("./components/jobs/Jobs")),
  },
  {
    path: "/jobs/0",
    component: lazy(() => import("./components/jobs/CreateJob")),
  },
  {
    path: "/jobs/:id",
    component: lazy(() => import("./components/jobs/Job")),
  },
  {
    path: "/jobs/:id/calendar",
    component: lazy(() => import("./components/jobs/JobTimelineCalendar")),
  },
  {
    path: "/jobs-vendor/:id",
    component: lazy(() => import("./components/jobs/JobVendor")),
  },
  {
    path: "/jobs-customer/:id",
    component: lazy(() => import("./components/jobs/JobCustomer")),
  },
  {
    path: "/jobleads",
    component: lazy(() => import("./components/jobleads/JobLeads")),
  },
  {
    path: "/jobleads/:id",
    component: lazy(() => import("./components/jobleads/LeadQuote")),
  },
  {
    path: "/quotes",
    component: lazy(() => import("./components/jobleads/JobLeads")),
  },
  {
    path: "/quotes/:id",
    component: lazy(() => import("./components/jobleads/LeadQuote")),
  },
  {
    path: "/reports/invoicingitems",
    component: lazy(() => import("./components/users/InvoicingVendorItems")),
  },
  {
    path: "/reports/receivepayment",
    component: lazy(() => import("./components/jobs/ReceivePayment")),
  },
  {
    path: "/reports/payments",
    component: lazy(() => import("./components/reports/Payments")),
  },
  {
    path: "/reports/pendingbills",
    component: lazy(() => import("./components/reports/PendingBills")),
  },
  {
    path: "/reports/vendorpaymenthistory",
    component: lazy(() => import("./components/users/VendorPaymentHistory")),
  },
  {
    path: "/support",
    component: lazy(() => import("./components/users/Support")),
  },
  {
    path: "/admin/catalog",
    component: lazy(() => import("./components/admin/catalog/Catalog")),
  },
  {
    path: "/admin/suppliers",
    component: lazy(() => import("./components/admin/suppliers/Suppliers")),
  },
  {
    path: "/admin/trades",
    component: lazy(() => import("./components/admin/trades/Trades")),
  },
  {
    path: "/admin/partners",
    component: lazy(() => import("./components/partners/Partners")),
  },
  {
    path: "/admin/partners/:id",
    component: lazy(() => import("./components/partners/Partner")),
  },
  {
    path: "/admin/users",
    component: lazy(() => import("./components/users/Users")),
  },
  {
    path: "/admin/users/:id",
    component: lazy(() => import("./components/users/User")),
  },
  {
    path: "/admin/emailtemplates",
    component: lazy(() => import("./components/users/EmailTemplates")),
  },
  {
    path: "/partner",
    component: lazy(() => import("./components/partner/Partner")),
  },
  {
    path: "/partner/teamusers",
    component: lazy(() => import("./components/partner/TeamUsers")),
  },
  {
    path: "/partner/vendors",
    component: lazy(() => import("./components/partner/VendorUsers")),
  },
  {
    path: "/admin/billing",
    component: lazy(() => import("./components/admin/billing/Billing")),
  },
];
