import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Button } from "react-bootstrap";
import gql from "graphql-tag";
import PartnerPlaidLink from "./PartnerPlaidLink";
import { formatMoney } from "../../utils/stringutils";

export default function PartnerPlaidActivation(props) {
  const { loading, data } = useQuery(QUERY, {
    fetchPolicy: "no-cache",
    variables: { id: props.partner_id },
  });

  const [plaidLinkToken, setPlaidLinkToken] = useState(null);
  const [plaidConnecting, setPlaidConnecting] = useState(false);

  const [getPlaidLink] = useMutation(GET_PLAID_LINK);
  const [activatePlaid] = useMutation(ACTIVATE_PLAID, {
    refetchQueries: ["getPartners", "getPartner"],
  });

  useEffect(() => {
    getPlaidLink({
      variables: {
        partner_id: props.partner_id,
      },
    })
      .then((result) => {
        setPlaidLinkToken(result.data.getPlaidToken.link_token);
      })
      .catch((error) => {});
  }, []);

  if (loading) return null;

  var partner = data.partner[0];

  return (
    <>
      {plaidConnecting ? (
        <Button size="sm" variant="secondary">
          <i className="fa fa-hourglass-half mr-2"></i> Plaid Connecting...
        </Button>
      ) : (
        <>
          {partner.plaid_account_id == null ? (
            <>
              {plaidLinkToken !== null && (
                <PartnerPlaidLink
                  plaidLinkToken={plaidLinkToken}
                  handleActivation={(token, metadata) => {
                    setPlaidConnecting(true);
                    activatePlaid({
                      variables: {
                        partner_id: props.partner_id,
                        public_token: token,
                        account_id: metadata.account_id,
                        customer_name: partner.name,
                      },
                    })
                      .then((result) => {
                        setPlaidConnecting(false);
                      })
                      .catch((error) => {});
                  }}
                />
              )}
            </>
          ) : (
            <>
              <p>
                <i className="fa fa-check mr-2"></i> Your bank account is
                linked!
              </p>
              <p>
                Current account balance:{" "}
                {formatMoney(partner.plaid_account_balance)}
              </p>
            </>
          )}
        </>
      )}
    </>
  );
}

const GET_PLAID_LINK = gql`
  mutation getPlaidToken($partner_id: Int!) {
    getPlaidToken(partner_id: $partner_id) {
      success
      message
      link_token
    }
  }
`;

const ACTIVATE_PLAID = gql`
  mutation getPlaidToken(
    $partner_id: Int!
    $public_token: String!
    $account_id: String!
    $customer_name: String!
  ) {
    createStripeCustomer(
      partner_id: $partner_id
      public_token: $public_token
      account_id: $account_id
      customer_name: $customer_name
    ) {
      success
      message
    }
  }
`;

const QUERY = gql`
  query getPartner($id: Int!) {
    partner(where: { id: { _eq: $id } }) {
      id
      name
      is_active
      plaid_access_token
      plaid_account_id
      stripe_customer_id
      plaid_account_balance
    }
  }
`;
