import React, { Component } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, Modal } from "react-bootstrap";

export default function ModalQuickAdd(props) {
  let history = useHistory();

  return (
    <Modal className="modal-slide pt-0" show={props.show} onHide={props.close}>
      <Modal.Header closeButton className="p-3">
        <Modal.Title as="h5">Add New</Modal.Title>
      </Modal.Header>

      <Modal.Body className="m-0 pt-4">
        <Row>
          <Col xs={6}>
            <Button
              block
              variant="primary px-0 pt-4 pb-2 mb-4"
              onClick={() => {
                history.push("/jobs/0");
                props.close();
              }}
            >
              <span className="fas fa-wrench fa-2x"></span>
              <p className="pt-2">Job</p>
            </Button>
            <Button
              block
              variant="primary px-0 pt-4 pb-2 mb-4"
              onClick={() => {
                history.push("/clients/0");
                props.close();
              }}
            >
              <span className="fas fa-users fa-2x"></span>
              <p className="pt-2">Client</p>
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              block
              variant="primary px-0 pt-4 pb-2 mb-4"
              onClick={() => {
                history.push("/admin/users/0");
                props.close();
              }}
            >
              <span className="fas fa-user fa-2x"></span>
              <p className="pt-2">User</p>
            </Button>
            <Button
              block
              variant="primary px-0 pt-4 pb-2 mb-4"
              onClick={() => {
                props.close();
                props.showModal("Task");
              }}
            >
              <span className="ion ion-ios-list-box fa-2x"></span>
              <p className="pt-2">Task</p>
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
