import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Sidenav from "../../vendor/libs/sidenav";
import ScrollArea from "react-scrollbar";
import layoutHelpers from "./helpers";
import useLocalStorage from "../../utils/localStorageUtils";

export default function LayoutSidenav(props) {
  const [leftNavExpanded, setLeftNavExpanded] = useLocalStorage(
    "left_nav_expanded",
    false
  );

  useEffect(() => {
    if (leftNavExpanded && layoutHelpers.isCollapsed()) {
      layoutHelpers.toggleCollapsed();
    }
    if (!leftNavExpanded && !layoutHelpers.isCollapsed()) {
      layoutHelpers.toggleCollapsed();
    }
  }, [leftNavExpanded]);

  function layoutSidenavClasses() {
    let bg = "sidenav-theme";

    if (
      props.orientation === "horizontal" &&
      (bg.indexOf(" sidenav-dark") !== -1 ||
        bg.indexOf(" sidenav-light") !== -1)
    ) {
      bg = bg
        .replace(" sidenav-dark", "")
        .replace(" sidenav-light", "")
        .replace("-darker", "")
        .replace("-dark", "");
    }

    return (
      `bg-${bg} ` +
      (props.orientation !== "horizontal"
        ? "layout-sidenav"
        : "layout-sidenav-horizontal container-p-x flex-grow-0")
    );
  }

  function toggleSidenav(e) {
    e.preventDefault();
    layoutHelpers.toggleCollapsed();
    setLeftNavExpanded(!layoutHelpers.isCollapsed());
  }

  function isMenuActive(url) {
    return props.location.pathname.indexOf(url) === 0;
  }

  function isMenuOpen(url) {
    return (
      props.location.pathname.indexOf(url) === 0 &&
      props.orientation !== "horizontal"
    );
  }

  let defaultRole = props.claims.default_role;

  if (
    (defaultRole === "vendor" || defaultRole === "customer") &&
    (window.location.pathname == "/dashboard" ||
      window.location.pathname == "/")
  ) {
    window.location.href = "/jobs";
  }
  return (
    <>
      <Sidenav
        orientation={props.orientation}
        className={layoutSidenavClasses()}
      >
        {/* Brand demo (see src/demo.css) */}
        {props.orientation !== "horizontal" && (
          <React.Fragment>
            <div className="app-brand demo">
              <Link
                to="/"
                className="app-brand-text demo sidenav-text font-weight-normal ml-1 mt-1"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/logo_inverse.png`}
                  alt="ProServe Alliance"
                  className="app-brand-logo demo"
                />

                <img
                  src={`${process.env.PUBLIC_URL}/img/mark.png`}
                  alt="ProServe Alliance"
                  className="app-brand-mark demo"
                />
              </Link>
              <a
                href="#toggle"
                className="layout-sidenav-toggle sidenav-link text-large ml-auto"
                onClick={toggleSidenav}
              >
                <i className="ion ion-md-menu align-middle"></i>
              </a>
            </div>
            <Sidenav.Divider className="mt-0" />
          </React.Fragment>
        )}

        <ScrollArea>
          <div
            className={`sidenav-inner ${
              props.orientation !== "horizontal" ? "py-1" : ""
            }`}
          >
            {defaultRole != "vendor" && defaultRole != "customer" && (
              <Sidenav.RouterLink to="/dashboard" icon="ion ion-md-speedometer">
                Dashboard
              </Sidenav.RouterLink>
            )}
            <Sidenav.RouterLink to="/jobs" icon="fas fa-wrench">
              Jobs
            </Sidenav.RouterLink>
            {/* <Sidenav.RouterLink to="/addresses" icon="fas fa-map-marker">
                Addresses
              </Sidenav.RouterLink> */}
            {(defaultRole == "teamadmin" || defaultRole === "superadmin") && (
              <>
                <Sidenav.RouterLink to="/clients" icon="fas fa-users">
                  Clients
                </Sidenav.RouterLink>

                <Sidenav.RouterLink
                  to={`/partner/vendors`}
                  icon="fas fa-hard-hat"
                >
                  Vendors
                </Sidenav.RouterLink>
                <Sidenav.RouterLink
                  to={`/partner/teamusers`}
                  icon="fas fa-user-lock"
                >
                  Team Members
                </Sidenav.RouterLink>
              </>
            )}
            {/* <Sidenav.RouterLink to="/calendar" icon="fas fa-calendar">
                Calendar
              </Sidenav.RouterLink> */}
            {/* <Sidenav.RouterLink to="/messages" icon="ion ion-ios-chatboxes">
                Messages
              </Sidenav.RouterLink> */}

            {defaultRole != "vendor" && defaultRole != "customer" && (
              <Sidenav.Menu
                icon="fas fa-clipboard"
                linkText="Reports"
                active={isMenuActive("/reports")}
                open={isMenuOpen("/reports")}
              >
                <Sidenav.RouterLink to="/reports/invoicingitems">
                  Invoicing Vendor Payments
                </Sidenav.RouterLink>
                <Sidenav.RouterLink to="/reports/vendorpaymenthistory">
                  Vendor Payment History
                </Sidenav.RouterLink>
                {(defaultRole == "teamadmin" ||
                  defaultRole === "superadmin") && (
                  <>
                    <Sidenav.RouterLink to="/reports/receivepayment">
                      Receive Payment
                    </Sidenav.RouterLink>
                    <Sidenav.RouterLink to="/reports/payments">
                      Payments
                    </Sidenav.RouterLink>
                    <Sidenav.RouterLink to="/reports/pendingbills">
                      Pending Bills
                    </Sidenav.RouterLink>
                  </>
                )}
              </Sidenav.Menu>
            )}

            {defaultRole == "superadmin" && (
              <>
                <Sidenav.Menu
                  icon="fas fa-lock"
                  linkText="Admin"
                  active={isMenuActive("/admin")}
                  open={isMenuOpen("/admin")}
                >
                  <Sidenav.RouterLink to="/jobleads">Leads</Sidenav.RouterLink>
                  <Sidenav.RouterLink to="/admin/users">
                    All Users
                  </Sidenav.RouterLink>
                  <Sidenav.RouterLink to="/admin/partners">
                    Partners
                  </Sidenav.RouterLink>
                  <Sidenav.RouterLink to="/admin/billing">
                    Billing
                  </Sidenav.RouterLink>
                  <Sidenav.RouterLink to="/admin/catalog">
                    Job Item Catalog
                  </Sidenav.RouterLink>
                  <Sidenav.RouterLink to="/admin/suppliers">
                    Suppliers
                  </Sidenav.RouterLink>
                  <Sidenav.RouterLink to="/admin/trades">
                    Trades
                  </Sidenav.RouterLink>
                </Sidenav.Menu>
              </>
            )}

            <Sidenav.Divider className="mb-1" />
            <Sidenav.Header className="small font-weight-semibold">
              SUPPORT
            </Sidenav.Header>
            <a
              className="sidenav-item d-block "
              target="_blank"
              href="https://sso.teachable.com/secure/teachable_accounts/sign_in"
            >
              <div className="sidenav-link ">
                <i className="sidenav-icon ion ion-ios-book"></i>
                <div>Knowledge Base</div>
              </div>
            </a>

            <Sidenav.RouterLink to="/support" icon="ion ion-md-help-buoy">
              Support Request
            </Sidenav.RouterLink>
          </div>
        </ScrollArea>
      </Sidenav>
    </>
  );
}
