import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import Select from "react-select";
import { getCurrentPartnerId } from "../../../utils/auth/partnerhelpers";

export default function UserSelector(props) {
  const { loading, data } = useQuery(QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      role_names: props.roleNames,
      partner_id: getCurrentPartnerId(),
    },
  });

  useEffect(() => {
    if (props.value !== null) {
    }
  }, [props.value]);

  if (loading) return null;

  function handleChange(e) {
    if (e != null && !Array.isArray(e)) {
      e = [e];
    }

    if (props.isMulti) {
      props.onChange(
        Array.isArray(e)
          ? e.map(function (v, index) {
              return v.value;
            })
          : []
      );
    } else {
      props.onChange(e ? e[0].value : null);
    }
  }

  function handleBlur() {
    props.onBlur(props.name, true);
  }

  const options = data.user.map(function (e, index) {
    return {
      value: e.id,
      label: e.full_name,
      key: index,
    };
  });

  const selected = props.isMulti
    ? _.filter(options, function (o) {
        return _.includes(props.value, o.value);
      })
    : options.filter((option) => option.value == props.value);

  return (
    <Select
      key={`my_unique_select_key__${props.value}`}
      id={props.name}
      options={options}
      onChange={handleChange}
      onBlur={handleBlur}
      defaultValue={selected}
      isMulti={props.isMulti}
      closeMenuOnSelect={!props.isMulti}
      isClearable={props.isClearable}
      placeholder={props.placeholder ? props.placeholder : "None"}
      isDisabled={props.isDisabled}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
      }}
    />
  );
}

export const QUERY = gql`
  query getUsersInRole($role_names: [String!], $partner_id: Int!) {
    user(
      order_by: { full_name: asc }
      where: {
        _and: [
          { user_partners: { partner_id: { _eq: $partner_id } } }
          { user_roles: { role: { name: { _in: $role_names } } } }
        ]
      }
    ) {
      id
      full_name
    }
  }
`;
