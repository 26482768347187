import React, { useState, useEffect } from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";
import gql from "graphql-tag";
import { Button, Nav } from "react-bootstrap";
import { useQuery } from "@apollo/react-hooks";
import Select from "react-select";
import {
  getCurrentPartnerId,
  setCurrentPartnerId,
} from "../../../utils/auth/partnerhelpers";
import PartnerPlaidActivation from "../../partners/PartnerPlaidActivation";
import ConnectToQuickBooks from "../../partners/ConnectToQuickBooks";
import QuickbooksDefaultItemModal from "../../partners/QuickbooksDefaultItemModal";

export default function PartnerNavSelector(props) {
  let history = useHistory();
  const [modalDefaultItemOpen, setModalDefaultItemOpen] = useState(false);
  const [partnerSelectOpen, setPartnerSelectOpen] = useState(false);
  const [redirectLink, setRedirectLink] = useState(null);
  const [partnerId, setPartnerId] = useState(getCurrentPartnerId());
  const { loading, data, error } = useQuery(QUERY);
  let { pid } = useParams();

  const partnerChangeListener = (e) => {
    setPartnerId(getCurrentPartnerId());
  };
  useEffect(() => {
    window.addEventListener("updatePartner", partnerChangeListener);
    return () =>
      window.removeEventListener("updatePartner", partnerChangeListener);
  }, []);

  useEffect(() => {
    if (data && data.partner) {
      let currentPartner = data.partner.filter((n) => n.id == partnerId)[0];
      if (
        currentPartner &&
        currentPartner.qb_item_id == null &&
        currentPartner.qb_access_token != null
      ) {
        setModalDefaultItemOpen(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (redirectLink != null) {
      // setPartnerId(redirectLink.partnerId);
      // setCurrentPartnerId(redirectLink.partnerId);
      // setPartnerSelectOpen(false);
      setRedirectLink(null);
    }
  }, [redirectLink]);

  if (loading || error || !data.partner) return <div></div>;

  function handleChange(option) {
    setPartnerSelectOpen(false);
    setRedirectLink({
      partnerId: option.value,
      link: window.location.pathname + window.location.search,
    });
    //history.push(window.location.pathname + window.location.search);
  }

  const options = data.partner.map(function (e, index) {
    return {
      value: e.id,
      label: e.name,
      key: index,
    };
  });

  if (partnerId == 0) {
    setPartnerId(options[0].value);
    setCurrentPartnerId(options[0].value);
    // setRedirectLink({
    //   partnerId: options[0].value,
    //   link: window.location.pathname + window.location.search,
    // });
    history.push(window.location.pathname + window.location.search);
  }

  if (pid != null) {
    setCurrentPartnerId(pid);
    window.location.href = "/dashboard";
  }

  let currentPartner = data.partner.filter((n) => n.id == partnerId)[0];

  if (currentPartner == undefined) {
    setPartnerId(data.partner[0].id);
    setCurrentPartnerId(data.partner[0].id);
  }

  const selectedPartner = options.filter(
    (option) => option.value == partnerId
  )[0];

  const partnerName = selectedPartner ? selectedPartner.label : "None";

  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  };

  if (redirectLink != null) {
    return <Redirect to={`/dashboard/${redirectLink.partnerId}`} />;
  }

  return (
    <>
      {currentPartner != null && currentPartner?.is_test_partner == true && (
        <Nav.Link className="demo-navbar-notifications mr-lg-2">
          <div style={{ position: "relative" }}>
            <a className="btn btn-sm btn-danger" href="#">
              -- <i className="fa fa-exclamation-triangle"></i> TEST MODE --
            </a>
          </div>
        </Nav.Link>
      )}
      {currentPartner != null &&
        currentPartner.qb_access_token != null &&
        currentPartner.qb_item_id == null && (
          <>
            <Nav.Link className="demo-navbar-notifications mr-lg-2">
              <div style={{ position: "relative" }}>
                <a
                  className="btn btn-sm btn-danger"
                  href="#"
                  onClick={() => {
                    setModalDefaultItemOpen(true);
                  }}
                >
                  <i className="fa fa-exclamation-triangle"></i> YOU ARE MISSING
                  KEY QUICKBOOKS SETTINGS
                </a>
              </div>
            </Nav.Link>
            {modalDefaultItemOpen && (
              <QuickbooksDefaultItemModal
                modalOpen={modalDefaultItemOpen}
                setModalOpen={setModalDefaultItemOpen}
              />
            )}
          </>
        )}

      {currentPartner != null &&
        currentPartner?.is_test_partner != true &&
        currentPartner?.qb_access_token == null &&
        (props.claims.default_role === "superadmin" ||
          props.claims.default_role === "teamadmin") && (
          <Nav.Link className="demo-navbar-notifications mr-lg-2">
            <div style={{ position: "relative" }}>
              <ConnectToQuickBooks
                is_test_partner={currentPartner?.is_test_partner}
              />
            </div>
          </Nav.Link>
        )}
      {currentPartner != null &&
        currentPartner.stripe_customer_id == null &&
        (props.claims.default_role === "superadmin" ||
          props.claims.default_role === "teamadmin") && (
          <Nav.Link className="demo-navbar-notifications mr-lg-2">
            <div style={{ position: "relative" }}>
              <PartnerPlaidActivation partner_id={partnerId} />
            </div>
          </Nav.Link>
        )}

      <Nav.Link className="demo-navbar-notifications mr-lg-2">
        <div style={{ position: "relative" }}>
          <Button
            variant="default"
            size="sm"
            className="rounded-pill"
            onClick={() => setPartnerSelectOpen(true)}
          >
            <i className="fas fa-users"></i>{" "}
            <strong>&nbsp;{partnerName}</strong>{" "}
            <i className="fas fa-angle-down"></i>
          </Button>
          {partnerSelectOpen ? (
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 4,
                boxShadow: `0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1)`,
                marginTop: 8,
                position: "absolute",
                zIndex: 2,
                fontSize: "13px",
              }}
            >
              <Select
                id="partnerId"
                menuIsOpen
                autoFocus
                styles={selectStyles}
                placeholder="Search..."
                options={options}
                onChange={handleChange}
                defaultValue={options.filter(
                  (option) => option.value == partnerId
                )}
                isMulti={false}
                isClearable={false}
              />
            </div>
          ) : null}
          {partnerSelectOpen ? (
            <div
              style={{
                bottom: 0,
                left: 0,
                top: 0,
                right: 0,
                position: "fixed",
                zIndex: 1,
              }}
              onClick={() => setPartnerSelectOpen(false)}
            />
          ) : null}
        </div>
      </Nav.Link>
    </>
  );
}

export const QUERY = gql`
  query getPartners {
    partner(where: { is_active: { _eq: true } }, order_by: { name: asc }) {
      id
      name
      plaid_account_id
      stripe_customer_id
      qb_access_token
      is_test_partner
      qb_item_id
    }
  }
`;
