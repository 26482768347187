import React, { Component } from "react";
import LayoutNavbar from "./LayoutNavbar";
import LayoutSidenav from "./LayoutSidenav";
import LayoutFooter from "./LayoutFooter";
import layoutHelpers from "./helpers";
import ErrorBoundary from "../../components/common/ErrorBoundary";
import { ToastContainer } from "react-toastify";

class Layout2 extends Component {
  closeSidenav(e) {
    e.preventDefault();
    layoutHelpers.setCollapsed(true);
  }

  componentDidMount() {
    layoutHelpers.init();
    layoutHelpers.update();
    layoutHelpers.setAutoUpdate(true);
  }

  componentWillUnmount() {
    layoutHelpers.destroy();
  }

  render() {
    if (!this.props.user) {
      if (
        this.props.claims.default_role == "" &&
        this.props.claims.initial == false
      ) {
        window.location.href = "/login";
        return null;
      }
    }

    if (this.props.claims.initial == true) {
      return null;
    }

    return (
      <div className="layout-wrapper layout-2">
        <div className="layout-inner">
          <LayoutSidenav {...this.props} />

          <div className="layout-container">
            <LayoutNavbar {...this.props} />

            <div className="layout-content">
              <div className="container-fluid flex-grow-1 container-p-y">
                <ErrorBoundary>{this.props.children}</ErrorBoundary>
              </div>

              <LayoutFooter {...this.props} />
            </div>
          </div>
        </div>
        <div className="layout-overlay" onClick={this.closeSidenav}></div>
        <ToastContainer autoClose={2000} newestOnTop={true} />
      </div>
    );
  }
}

export default Layout2;
