import React, { useCallback } from "react";
import { Button } from "react-bootstrap";

import { usePlaidLink } from "react-plaid-link";

export default function PartnerPlaidLink(props) {
  const onSuccess = useCallback((token, metadata) => {
    props.handleActivation(token, metadata);
    // run stripe onboard here
  }, []);

  const config = {
    token: props.plaidLinkToken,
    onSuccess,
  };

  const { open, ready, error } = usePlaidLink(config);

  if (error) return <span>{JSON.stringify(error)}</span>;

  return (
    <Button
      size="sm"
      variant="danger"
      onClick={() => {
        open();
      }}
      disabled={!ready}
    >
      <i className="fa fa-exclamation-triangle mr-2"></i> Activate My Account
    </Button>
  );
}
